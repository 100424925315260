// 保存用户登录信息
export const SAVEUSERINFO = 'SAVEUSERINFO';

// 清除用户登录信息
export const CLEARUSERINFO = 'CLEARUSERINFO';

//保存首页数据
export const SAVEHOMESTATE = 'SAVEHOMESTATE';

// 保存主题详情页数据
export const SAVETOPICSTATE = 'SAVETOPICSTATE';




